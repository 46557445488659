import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Columns } from "../components/Containers"
import { PaymentForm } from "../components/Forms"
import { ButtonGroupMap } from "../components/Button"
import { Text } from "../components/Core"

const makeAPayment = ({ data, pageContext, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext

  return (
    <Layout language={language}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      <div className="make-a-payment">
        <div className="columns top-section color-back">
          <div className="column is-3" />
          <div className="column">
            <h1 style={{ marginTop: 0, marginBottom: 0 }}>{post.heading}</h1>
          </div>
          <div className="column is-3" />
        </div>

        <Section>
          <Columns sideColumnsSize={3}>
            <div className="column">
              <Text text={post.payOnline.blurb} />
              <ButtonGroupMap buttons={post.payOnline.buttons} isCentered />
            </div>
          </Columns>
        </Section>

        <Section colorBack id="online-payment">
          <Columns sideColumnsSize={3}>
            <div className="column">
              <PaymentForm />
            </div>
          </Columns>
        </Section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query MyQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        payOnline {
          blurb
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
        }
      }
    }
  }
`

export default makeAPayment
